import React, { useEffect, useState } from 'react'

import {
  Alert,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

type Errors = {
  fi: { [key: string]: string }
  sv: { [key: string]: string }
  en: { [key: string]: string }
}

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => {
  const [unableToFetch, setUnableToFetch] = useState(false)
  const [errors, setErrors] = useState<Errors>({ fi: {}, sv: {}, en: {} })

  useEffect(() => {
    const fetchErrors = async () => {
      try {
        const response = await Promise.all(
          ['fi', 'en', 'sv'].map(locale =>
            fetch(
              `https://tyomarkkinatori.fi/.rest/localization/v1?locale=${locale}&category=widgets&module=errors`
            )
          )
        )
        const fi = await response[0].json()
        const en = await response[1].json()
        const sv = await response[2].json()
        setErrors({ fi, en, sv })
      } catch (e) {
        setUnableToFetch(true)
      }
    }

    fetchErrors()
  }, [])

  const getError = (id: string, locale: 'fi' | 'en' | 'sv') => {
    if (!errors[locale]) {
      return ''
    }

    return errors[locale][id]
  }

  return (
    <Content
      heading="Virheviestit"
      lead="Työmarkkinatorilla tällä hetkellä käytössä olevat yleiset virheviestit."
    >
      <Section>
        {unableToFetch && (
          <Alert color="danger">Käännösten haku epäonnistui.</Alert>
        )}
        {Object.keys(errors.fi).length > 0 && (
          <Table
            divider="horizontal"
            responsive
          >
            <TableHeader>
              <TableRow>
                <TableHeaderCell>ID</TableHeaderCell>
                <TableHeaderCell>Suomi</TableHeaderCell>
                <TableHeaderCell>Ruotsi</TableHeaderCell>
                <TableHeaderCell>Englanti</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {Object.keys(errors.fi)
                .filter(error => !error.startsWith('_'))
                .map(error => (
                  <TableRow key={error}>
                    <TableCell>
                      <Code>{error}</Code>
                    </TableCell>
                    <TableCell>{getError(error, 'fi')}</TableCell>
                    <TableCell>{getError(error, 'sv')}</TableCell>
                    <TableCell>{getError(error, 'en')}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        )}
      </Section>
    </Content>
  )
}

export default Page
